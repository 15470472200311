import React, {useState} from "react";
import InputForm from "./InputForm";

function InputFormCheck(props) {
    const [isValid, setIsValid] = useState(true);
  function submitFormChecker(userData) {
    if (
      props.users.filter((user) => {
        return userData.name === user.name;
      }).length === 0
    ) {
        setIsValid(true);
      props.onSubmitForm(userData);
    } else {
        setIsValid(false);
      props.onBadInput(userData.name);
    }
  }
  if (isValid) {
    return <InputForm onSubmitForm={submitFormChecker} />;
  }
  return <div>
    <h2>Check your input</h2>
    <InputForm onSubmitForm={submitFormChecker} />
  </div>;

}

export default InputFormCheck;
