import React from 'react';
import styles from './UserList.module.css';
import UserList from './UserList';

function UserListDisplay (props) {
    if (props.users.length<1) {
        return <div className={styles['user-list']}>
            <p>add your friends!</p>
        </div>
    }

    return <div className={styles['user-list']}>
        <h2 className={`${styles['user-list']} ${styles.heading}`}>Honour Roll</h2>
        <UserList users={props.users}/>
    </div>
}

export default UserListDisplay;