import React from 'react';
import styles from './UserItem.module.css';

// want to display user, age, status in an item

function UserItem (props) {
    return <div className={styles['user-item']}>
        <h2 className={styles.h2}>{props.name}</h2>
        <div>alive {props.age} years</div>
        <h2 className={styles.h2}>{props.status}</h2>
    </div>
}

export default UserItem;