import React from 'react';
import styles from './UserList.module.css';
import UserItem from '../UserItem/UserItem';

function UserList (props) {
    return <ul className={styles.ul}>
        {props.users.map(user => <UserItem 
        key={user.name}
        name={user.name} age={user.age} status={user.status}/>)}
    </ul>
}

export default UserList;