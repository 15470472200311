import React, { useState } from "react";
import styles from "./InputForm.module.css";

function Randomizer() {
  //future: read from config file
  const seed = Math.floor(Math.random() * 100);
  if (seed <= 30) {
    const dumbs = [
      "Dumb",
      "Moron",
      "Idiot",
      "Fool",
      "Buffoon",
      "Dim Wit",
      "Thick-Skulled",
      "Dense",
      "Minorly Intelligent",
      "Quite Dumb",
      "Coconut Head",
      "Smooth Brain",
    ];
    return dumbs[Math.floor(Math.random() * dumbs.length)];
  } else if (seed <= 40) {
    const almost = [
      "Almost smart",
      "Moderately intellectual",
      "Mediocre brain power",
      "Extremely mediocre",
      "Passed high school",
    ];
    return almost[Math.floor(Math.random() * almost.length)];
  } else if (seed <= 50) {
    const smart = [
      "Smart?",
      "LS viewer",
      "Genius",
      "Big brain",
      "Galaxy brain",
    ];
    return smart[Math.floor(Math.random() * smart.length)];
  }
  return "Average";
}

function InputForm(props) {
  const [enteredName, setEnteredName] = useState("");
  const [enteredAge, setEnteredAge] = useState("");

  function submitHandler(event) {
    event.preventDefault();
    if (enteredName.trim().length === 0 || enteredAge.trim().length === 0) {
        return;
    }
    if (+enteredAge < 1) {
        return;
    }
    const userData = {
      name: enteredName,
      age: +enteredAge,
      status: Randomizer(),
    };
    setEnteredName("");
    setEnteredAge("");
    props.onSubmitForm(userData);
  }

  function nameChangeHandler(event) {
    setEnteredName(event.target.value);
  }
  function ageChangeHandler(event) {
    setEnteredAge(event.target.value);
  }

  function demoHandler(event) {
    event.preventDefault();
    const THEGANG = [
      { name: "Justin", age: 19, status: "Pretty dumb" },
      { name: "Chris", age: 19, status: "Quite dumb" },
      { name: "Nicky", age: 20, status: "Surprisingly, intelligent" },
    ];
    for (const user of THEGANG) {
      const userData = {
        name: user.name,
        age: +user.age,
        status: Randomizer(),
      };
      props.onSubmitForm(userData);
    }
  }

  return (
    <>
      <div className={styles.demo}>
        <button onClick={demoHandler}>Try Justin, Chris, Nicky</button>
      </div>
      <form className={styles["input-form"]} onSubmit={submitHandler}>
        <div style={{ margin: "0.5rem" }}>
          <label htmlFor="userName">Enter Name</label>
        </div>
        <input id="userName"
          type="text"
          value={enteredName}
          onChange={nameChangeHandler}
        ></input>
        <div style={{ margin: "0.5rem" }}>
          <label htmlFor="userAge">Enter Age</label>
        </div>
        <input id="userAge" value={enteredAge} onChange={ageChangeHandler}></input>
        <div style={{ margin: "0.5rem" }}>
          <button type="submit">Find out</button>
        </div>
      </form>
    </>
  );
}

export default InputForm;
